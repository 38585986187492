import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { Control, Controller, useFormState } from "react-hook-form";
import { ICandidate } from "../../data/Candidates";
import useNationalities from "../../data/Nationalities";
import useAreas from "../../data/Areas";
import useLanguages from "../../data/Languages";
import useQualifications from "../../data/Qualifications";
import useDrivingLicences from "../../data/DrivingLicences";
import useGenders from "../../data/Genders";
import useNoticePeriods from "../../data/NoticePeriod";

interface ICandidateBioFormProps {
  control: Control<ICandidate>;
  disabled?: boolean;
  candidateLoading?: boolean;
}

export default function CandidateBioForm({
  control,
  disabled,
  candidateLoading,
}: ICandidateBioFormProps) {
  const { errors } = useFormState({
    control,
  });
  const [nationalities, nationalitiesLoading] = useNationalities();
  const [genders, gendersLoading] = useGenders();
  const [areas, areasLoading] = useAreas();
  const [languages, languagesLoading] = useLanguages();
  const [qualifications, qualificationsLoading] = useQualifications();
  const [drivingLicences, drivingLicencesLoading] = useDrivingLicences();
  const [noticePeriods, noticePeriodsLoading] = useNoticePeriods();

  const loading =
    nationalitiesLoading ||
    gendersLoading ||
    areasLoading ||
    languagesLoading ||
    qualificationsLoading ||
    drivingLicencesLoading ||
    noticePeriodsLoading ||
    (candidateLoading ?? false);

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="BIO Details" />
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Box>
          {loading ? null : (
            <>
              <Controller
                name="id_number"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="ID Number"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors.id_number}
                    helperText={errors.id_number?.message}
                  />
                )}
              />
              <Controller
                name="nationality"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    options={Array.from(nationalities.keys())}
                    getOptionLabel={(option) => nationalities.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nationality"
                        variant="filled"
                        margin="dense"
                        error={!!errors.nationality}
                        helperText={errors.nationality?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    options={Array.from(genders.keys())}
                    getOptionLabel={(option) => genders.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Gender"
                        variant="filled"
                        margin="dense"
                        error={!!errors.gender}
                        helperText={errors.gender?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="salary"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Salary (Annual)"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R</InputAdornment>
                      ),
                    }}
                    error={!!errors.salary}
                    helperText={errors.salary?.message}
                  />
                )}
              />
              <Controller
                name="areas"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(areas.keys())}
                    getOptionLabel={(option) => areas.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={areas.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Areas"
                        variant="filled"
                        margin="dense"
                        error={!!errors.areas}
                        helperText={(errors.areas as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="languages"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(languages.keys())}
                    getOptionLabel={(option) => languages.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={languages.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Languages"
                        variant="filled"
                        margin="dense"
                        error={!!errors.languages}
                        helperText={(errors.languages as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="qualifications"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(qualifications.keys())}
                    getOptionLabel={(option) =>
                      qualifications.get(option) ?? ""
                    }
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={qualifications.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Qualifications"
                        variant="filled"
                        margin="dense"
                        error={!!errors.qualifications}
                        helperText={(errors.qualifications as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="driving_licence"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    options={Array.from(drivingLicences.keys())}
                    getOptionLabel={(option) =>
                      drivingLicences.get(option) ?? ""
                    }
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Driving Licence"
                        variant="filled"
                        margin="dense"
                        error={!!errors.driving_licence}
                        helperText={errors.driving_licence?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="notice_period"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    options={Array.from(noticePeriods.keys())}
                    getOptionLabel={(option) => noticePeriods.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Maximum Notice Period"
                        variant="filled"
                        margin="dense"
                        error={!!errors.notice_period}
                        helperText={errors.notice_period?.message}
                      />
                    )}
                  />
                )}
              />
              <Box>
                <Controller
                  name="permanent_resident"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                          color="primary"
                        />
                      }
                      label="Permanent Resident"
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="qualifies_for_bee"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                          color="primary"
                        />
                      }
                      label="BEE Qualified"
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="valid_work_permit"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                          color="primary"
                        />
                      }
                      label="SA Working Permit"
                    />
                  )}
                />
              </Box>
              <Box>
                <Controller
                  name="has_own_car"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={disabled}
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                          color="primary"
                        />
                      }
                      label="Has Own Car"
                    />
                  )}
                />
              </Box>
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}
