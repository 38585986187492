import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grow from "@mui/material/Grow";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import PeopleOutlined from "@mui/icons-material/PeopleOutlined";
import WorkOutlineOutlined from "@mui/icons-material/WorkOutlineOutlined";
import {
  BrowserRouter,
  RouteComponentProps,
  Route,
  Switch,
  withRouter,
  Redirect,
  useHistory,
} from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CookiesProvider, useCookies } from "react-cookie";
import { SnackbarProvider } from "notistack";
import axios from "axios";

import CandidateList from "./components/candidate/CandidateListPage";
import ClientList from "./components/client/ClientListPage";
import ClientCreate from "./components/client/ClientCreatePage";
import CandidateCreate from "./components/candidate/CandidateCreatePage";
import ListItemButtonLink from "./components/misc/ListItemButtonLink";
import Login from "./components/login/LoginPage";
import "./AxiosConfig";
import { msalConfig } from "./AuthConfig";
import CandidateUpdate from "./components/candidate/CandidateUpdatePage";
import ClientUpdate from "./components/client/ClientUpdatePage";
import NameFastFind from "./components/misc/NameFastFind";

const pca = new PublicClientApplication(msalConfig);

export default function App() {
  return (
    <>
      <CssBaseline />
      <CookiesProvider>
        <MsalProvider instance={pca}>
          <BrowserRouter>
            <SnackbarProvider
              TransitionComponent={Grow as any}
              autoHideDuration={10000}
              hideIconVariant
            >
              <Switch>
                <Route exact path="/login">
                  <Login />
                </Route>
                <Route>
                  <Shell />
                </Route>
              </Switch>
            </SnackbarProvider>
          </BrowserRouter>
        </MsalProvider>
      </CookiesProvider>
    </>
  );
}

function AppShell({ location }: RouteComponentProps) {
  const history = useHistory();

  const activeRoute = (routeName: string) =>
    location.pathname.includes(routeName);

  const [cookies] = useCookies();

  const handleLogout = () => {
    axios
      .get("/logout/")
      .then(() => {
        history.push("/login/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        display: "grid",
        gridTemplateColumns: "73px 1fr",
        gridTemplateRows: "auto 1fr",
        gridTemplateAreas: `
                          "header header"
                          "nav main"
                          `,
      }}
    >
      <Box sx={{ gridArea: "header" }}>
        <AppBar position="relative" elevation={0}>
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              Action Appointments
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <NameFastFind />
            </Box>
            <Button color="inherit" onClick={handleLogout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          gridArea: "nav",
          borderRight: 1,
          borderColor: "grey.300",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        <List>
          <ListItemButtonLink
            icon={<WorkOutlineOutlined />}
            primary="Clients"
            to="/clients"
            selected={activeRoute("/clients")}
          />
          <ListItemButtonLink
            icon={<PeopleOutlined />}
            primary="Candidates"
            to="/candidates"
            selected={activeRoute("/candidates")}
          />
        </List>
      </Box>
      <Box sx={{ gridArea: "main", overflowY: "scroll" }}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/candidates" />
          </Route>
          <Route
            exact
            path="/clients"
            render={({ location }) =>
              cookies.identity ? (
                <ClientList />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          ></Route>
          <Route
            exact
            path="/clients/create"
            render={({ location }) =>
              cookies.identity ? (
                <ClientCreate />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          ></Route>
          <Route
            path="/clients/:id"
            render={({ location }) =>
              cookies.identity ? (
                <ClientUpdate />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          ></Route>
          <Route
            exact
            path="/candidates"
            render={({ location }) =>
              cookies.identity ? (
                <CandidateList />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          ></Route>
          <Route
            exact
            path="/candidates/create"
            render={({ location }) =>
              cookies.identity ? (
                <CandidateCreate />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          ></Route>
          <Route
            path="/candidates/:id"
            render={({ location }) =>
              cookies.identity ? (
                <CandidateUpdate />
              ) : (
                <Redirect
                  to={{
                    pathname: "/login",
                    state: { from: location },
                  }}
                />
              )
            }
          ></Route>
        </Switch>
      </Box>
    </Box>
  );
}

const Shell = withRouter(AppShell);
