import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { Control, Controller, useFormState } from "react-hook-form";
import useTitles from "../../data/Titles";
import { ICandidate } from "../../data/Candidates";
import Autocomplete from "@mui/material/Autocomplete";

interface ICandidateContactFormProps {
  control: Control<ICandidate>;
  disabled?: boolean;
  candidateLoading?: boolean;
}

export default function CandidateContactForm({
  control,
  disabled,
  candidateLoading,
}: ICandidateContactFormProps) {
  const { errors } = useFormState({
    control,
  });
  const [titles, titlesLoading] = useTitles();

  const loading = titlesLoading || (candidateLoading ?? false);

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="Contact Details" />
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Box>
          {loading ? null : (
            <>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    options={Array.from(titles.keys())}
                    getOptionLabel={(option) => titles.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Title"
                        variant="filled"
                        margin="dense"
                        error={!!errors.title}
                        helperText={errors.title?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
                <Controller
                    name="email_secondary"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            label="Email (secondary)"
                            variant="filled"
                            margin="dense"
                            disabled={disabled}
                            fullWidth
                            {...field}
                            error={!!errors.email_secondary}
                            helperText={errors.email_secondary?.message}
                        />
                    )}
                />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Address"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    multiline
                    rows={4}
                    fullWidth
                    {...field}
                    error={!!errors.address}
                    helperText={errors.address?.message}
                  />
                )}
              />
              <Controller
                name="phone_number_primary"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Phone (primary)"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    {...field}
                    error={!!errors.phone_number_primary}
                    helperText={errors.phone_number_primary?.message}
                  />
                )}
              />
              <Controller
                name="phone_number_secondary"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Phone (secondary)"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    {...field}
                    error={!!errors.phone_number_secondary}
                    helperText={errors.phone_number_secondary?.message}
                  />
                )}
              />
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}
