import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { Control, Controller, useFormState } from "react-hook-form";
import { ICandidate } from "../../data/Candidates";
import useCategories from "../../data/Categories";
import useSkills from "../../data/Skills";
import useExperience from "../../data/Experience";
import useStatuses from "../../data/Statuses";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

interface ICandidateOfficeFormProps {
  control: Control<ICandidate>;
  disabled?: boolean;
  candidateLoading?: boolean;
}

export default function CandidateOfficeForm({
  control,
  disabled,
  candidateLoading,
}: ICandidateOfficeFormProps) {
  const { errors } = useFormState({
    control,
  });
  const [categories, categoriesLoading] = useCategories();
  const [skills, skillsLoading] = useSkills();
  const [experience, experienceLoading] = useExperience();
  const [statuses, statusesLoading] = useStatuses();

  const loading =
    categoriesLoading ||
    skillsLoading ||
    experienceLoading ||
    statusesLoading ||
    (candidateLoading ?? false);

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="Office Use" />
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Box>
          {loading ? null : (
            <>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  console.log(field.value);
                  return (
                  <FormControl
                    sx={statuses.get(Number(field.value)) === "Placed" ? {outline: "0.2rem solid red", outlineOffset: "-0.5rem"} : {}}
                    fullWidth
                    variant="filled"
                    margin="dense"
                    error={!!errors.status}
                  >
                    <InputLabel id="status">Status</InputLabel>
                    <Select labelId="status" {...field} disabled={disabled}>
                      {Array.from(statuses, ([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.status && (
                      <FormHelperText>{errors.status?.message}</FormHelperText>
                    )}
                  </FormControl>
                )}}
              />
              <Controller
                name="categories"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(categories.keys())}
                    getOptionLabel={(option) => categories.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={categories.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Categories"
                        variant="filled"
                        margin="dense"
                        error={!!errors.categories}
                        helperText={(errors.categories as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="skills"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(skills.keys())}
                    getOptionLabel={(option) => skills.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={skills.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Skills"
                        variant="filled"
                        margin="dense"
                        error={!!errors.skills}
                        helperText={(errors.skills as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="experience"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(experience.keys())}
                    getOptionLabel={(option) => experience.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={experience.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Experience"
                        variant="filled"
                        margin="dense"
                        error={!!errors.experience}
                        helperText={(errors.experience as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="created_by"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Created By"
                    variant="filled"
                    margin="dense"
                    disabled
                    fullWidth
                    {...field}
                  />
                )}
              />
              <Controller
                name="modified"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Last Edited"
                    variant="filled"
                    margin="dense"
                    disabled
                    fullWidth
                    {...field}
                    value={new Date(field.value ?? 0).toLocaleDateString(
                      "en-GB",
                      dateOptions
                    )}
                  />
                )}
              />
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}
