import React from "react";
import Box from "@mui/material/Box";
import ListItemButton from "@mui/material/ListItemButton";
import { Link as RouterLink, LinkProps } from "react-router-dom";

interface ListItemButtonLinkProps {
  icon: React.ReactNode;
  primary: string;
  to: string;
  selected: boolean;
}

export default function ListItemButtonLink(props: ListItemButtonLinkProps) {
  const { icon, primary, to, selected } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, Omit<LinkProps, "to">>(function Link(
        itemProps,
        ref
      ) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItemButton
        sx={{
          p: 2,
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
        component={renderLink}
        selected={selected}
      >
        {icon}
        <Box sx={{ fontSize: "10px", lineHeight: "12px" }}>{primary}</Box>
      </ListItemButton>
    </li>
  );
}
