import React from "react";
import axios from "axios";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { candidateSchema, ICandidate } from "../../data/Candidates";
import CandidateContactForm from "./CandidateContactForm";
import CandidateBioForm from "./CandidateBioForm";
import NotesForm from "../misc/NotesForm";
import { useSnackbar } from "notistack";

const defaultValues: ICandidate = {
  name: "",
  title: null,
  id_number: "",
  phone_number_primary: "",
  phone_number_secondary: "",
  email: "",
  email_secondary: "",
  address: "",
  notice_period: 0,
  gender: null,
  qualifies_for_bee: false,
  valid_work_permit: false,
  permanent_resident: false,
  has_own_car: false,
  salary: "",
  nationality: null,
  driving_licence: null,
  areas: [],
  languages: [],
  qualifications: [],
  notes: [],
};

enum SubmissionStatus {
  Unsubmitted = "unsubmitted",
  Submitting = "submitting",
  Completed = "competed",
}

export default function CandidateCreate() {
  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = useForm<ICandidate>({
    defaultValues: defaultValues,
    resolver: yupResolver(candidateSchema),
  });

  React.useEffect(() => {
    document.title = "Candidates";
  }, []);

  const { enqueueSnackbar } = useSnackbar();

  const [submissionStatus, setSubmissionStatus] = React.useState(
    SubmissionStatus.Unsubmitted
  );

  const submitting = submissionStatus === SubmissionStatus.Submitting;

  React.useEffect(() => {
    if (isSubmitSuccessful && submissionStatus === SubmissionStatus.Completed) {
      setSubmissionStatus(SubmissionStatus.Unsubmitted);
      reset(defaultValues);
    }
  }, [isSubmitSuccessful, submissionStatus, reset]);

  const handleCreate: SubmitHandler<ICandidate> = (data) => {
    setSubmissionStatus(SubmissionStatus.Submitting);
    axios
      .post<ICandidate>("/candidates/", data)
      .then((response) => {
        setSubmissionStatus(SubmissionStatus.Completed);
        enqueueSnackbar(`${response.data.name} created.`, {
          action: (
            <IconButton
              component={RouterLink}
              to={`/candidates/${response.data.id}`}
            >
              <EditIcon sx={{ color: "white" }} />
            </IconButton>
          ),
          variant: "success",
        });
      })
      .catch((error) => {
        setSubmissionStatus(SubmissionStatus.Unsubmitted);
        console.log(error);
      });
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(handleCreate)}
      autoComplete="off"
      sx={{
        display: "grid",
        columnGap: 3,
        ml: 3,
        mr: 3,
        gridTemplateRows: `auto 1fr`,
        gridTemplateColumns: `1fr 1fr 1fr 1fr`,
        gridTemplateAreas: `
                  "toolbar toolbar toolbar toolbar"
                  "candidate bio notes _"
                  `,
      }}
    >
      <Box
        sx={{
          gridArea: "toolbar",
          borderBottom: 1,
          borderColor: "grey.300",
        }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            CANDIDATE
          </Typography>
          <LoadingButton
            type="submit"
            startIcon={<SaveIcon />}
            loading={submitting}
            loadingPosition="start"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Toolbar>
      </Box>
      <Box sx={{ gridArea: "candidate" }}>
        <CandidateContactForm control={control} disabled={submitting} />
      </Box>
      <Box sx={{ gridArea: "bio" }}>
        <CandidateBioForm control={control} disabled={submitting} />
      </Box>
      <Box sx={{ gridArea: "notes" }}>
        <NotesForm
          control={control}
          register={register}
          disabled={submitting}
        />
      </Box>
    </Box>
  );
}
