import axios from "axios";
import React from "react";

interface IQualification {
  id: number;
  name: string;
}

export default function useQualifications(): [Map<number, string>, boolean] {
  const [qualifications, setQualifications] = React.useState<
    Map<number, string>
  >(new Map());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<IQualification[]>("/qualifications/")
      .then((response) => {
        setQualifications(new Map(response.data.map((x) => [x.id, x.name])));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return [qualifications, loading];
}
