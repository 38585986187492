import * as yup from "yup";

export const contactSchema: yup.SchemaOf<IContact> = yup.object({
  id: yup.number().optional(),
  name: yup.string().required("Name is a required field."),
  position: yup.string().required("Position is a required field."),
  email: yup.string().email().required("Email address is a required field."),
  number: yup.string().required("Phone number is a required field."),
});

export interface IContact {
  id?: number;
  name: string;
  email: string;
  position: string;
  number: string;
}
