import React from "react";
import { styled, alpha } from "@mui/material/styles";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(3),
  width: "60ch",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

export default function NameFastFind() {
  const history = useHistory();
  const [value, setValue] = React.useState<number | null>(null);
  const [search, setSearch] = React.useState<string>("");
  const [options, setOptions] = React.useState<Map<number, string>>(new Map());
  const [loading, setLoading] = React.useState(true);

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (value !== null) {
      setValue(null);
      setSearch("");
      setOptions(new Map());
      history.push(`/candidates/${value}`);
    }
  };

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const timeoutHandler = setTimeout(() => {
      axios
        .get<[]>(`/candidates/names/?search=${search}&with_id=1`, {
          cancelToken: source.token,
        })
        .then((response) => {
          setOptions(new Map(response.data.map((x) => [x[0], x[1]])));
          setLoading(false);
        })
        .catch((error) => console.log(error));
    }, 300);

    return () => {
      clearTimeout(timeoutHandler);
      source.cancel();
    };
  }, [search]);

  return (
    <form onSubmit={submitForm}>
      <Autocomplete
        options={Array.from(options.keys())}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option}>
              {options.get(option)}
            </li>
          );
        }}
        getOptionLabel={(option) => options.get(option) ?? ""}
        onChange={(_e, v) => {
          setValue(v);
        }}
        onInputChange={(_event, search) => {
          setSearch(search);
        }}
        value={value}
        fullWidth
        loading={loading}
        filterOptions={(x) => x}
        renderInput={(params) => {
          const { InputLabelProps, InputProps, ...rest } = params;
          return (
            <Search ref={params.InputProps.ref}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                {...params.InputProps}
                {...rest}
                endAdornment={null}
                placeholder="Find..."
              />
            </Search>
          );
        }}
      />
    </form>
  );
}
