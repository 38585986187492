import * as yup from "yup";

export interface INote {
  id?: number;
  date: Date;
  text: string;
}

export interface INotes {
  notes: INote[];
}

export const noteSchema: yup.SchemaOf<INote> = yup.object({
  id: yup.number().optional(),
  date: yup.date().required(),
  text: yup.string().required("Note text is a required field."),
});
