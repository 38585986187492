import React from "react";
import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import { loginRequest } from "../../AuthConfig";
import axios from "axios";
import { useHistory } from "react-router";

enum LoginStatus {
  Startup = "startup", // Initial status before interaction occurs
  AcquireCSRF = "acquireCSRF", // Status set for csrf calls
  Login = "login", // Status set when all login calls occuring
  HandleRedirect = "handleRedirect", // Status set when handleRedirect in progress
  None = "none", // Status set when interaction is complete
}

export function Login() {
  const [loginStatus, setLoginStatus] = React.useState<LoginStatus>(
    LoginStatus.None
  );

  React.useEffect(() => {
    document.title = "Action Appointments";
  }, []);

  const { instance, inProgress } = useMsal();
  const history = useHistory();

  const loading = inProgress === "login" || loginStatus !== LoginStatus.None;

  const handleLogin = () => {
    setLoginStatus(LoginStatus.Startup);
    instance
      .loginPopup(loginRequest)
      .then((result) => {
        setLoginStatus(LoginStatus.AcquireCSRF);
        axios
          .get("/set-csrf/")
          .then(() => {
            setLoginStatus(LoginStatus.Login);
            axios
              .post("/social/azuread-v2-tenant-oauth2/", {
                access_token: result.idToken,
              })
              .then(() => {
                setLoginStatus(LoginStatus.HandleRedirect);
                history.push("/candidates");
              })
              .catch((error) => {
                setLoginStatus(LoginStatus.None);
                console.log(error);
              });
          })
          .catch((error) => {
            setLoginStatus(LoginStatus.None);
            console.log(error);
          });
      })
      .catch((error) => {
        setLoginStatus(LoginStatus.None);
        console.log(error);
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card sx={{ maxWidth: 407 }} variant="outlined">
        {loading ? <LinearProgress /> : null}
        <CardMedia
          component="img"
          alt="action appointments"
          height="351"
          width="395"
          image="/home-tree.webp"
          sx={{ pl: 1.5, pt: 2 }}
        />
        <CardContent sx={{ pb: 0.5 }}>
          <Typography
            gutterBottom
            variant="h5"
            component="div"
            sx={{ mb: "0.2em" }}
          >
            Action Appointments
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Development Recruitment Specialists
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            fullWidth={true}
            size="large"
            disableElevation
            color="error"
            onClick={handleLogin}
            disabled={loading}
          >
            Sign in
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}

export default Login;
