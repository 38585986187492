import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { Control, Controller } from "react-hook-form";
import { ICandidateFilter } from "./CandidateListPage";
import useNationalities from "../../data/Nationalities";
import useSkills from "../../data/Skills";
import useCategories from "../../data/Categories";
import useLanguages from "../../data/Languages";
import useAreas from "../../data/Areas";
import useExperience from "../../data/Experience";
import useDrivingLicences from "../../data/DrivingLicences";
import NameAutocomplete from "../misc/NameAutocomplete";
import useNoticePeriods from "../../data/NoticePeriod";
import useStatuses from "../../data/Statuses";

interface CandidateListFilterProps {
  control: Control<ICandidateFilter>;
}

export default function CandidateListFilter({
  control,
}: CandidateListFilterProps) {
  const [nationalities] = useNationalities();
  const [skills, skillsLoading] = useSkills();
  const [categories, categoriesLoading] = useCategories();
  const [languages, languagesLoading] = useLanguages();
  const [areas, areasLoading] = useAreas();
  const [experience, experienceLoading] = useExperience();
  const [drivingLicences, drivingLicencesLoading] = useDrivingLicences();
  const [noticePeriods, noticePeriodsLoading] = useNoticePeriods();
  const [statuses, statusesLoading] = useStatuses();

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: "grey.300" }}>
        <Typography variant="h6" component="div">
          Filters
        </Typography>
      </Toolbar>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <NameAutocomplete
              section="candidates"
              name={field.name}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        <Controller
          name="nationality"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(nationalities.keys())}
              getOptionLabel={(option) => nationalities.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nationality"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="skills"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(skills.keys())}
              getOptionLabel={(option) => skills.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={skillsLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={skills.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Skills"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="categories"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(categories.keys())}
              getOptionLabel={(option) => categories.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={categoriesLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={categories.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Categories"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="experience"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(experience.keys())}
              getOptionLabel={(option) => experience.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={experienceLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={experience.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Experience"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="languages"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(languages.keys())}
              getOptionLabel={(option) => languages.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={languagesLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={languages.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="areas"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(areas.keys())}
              getOptionLabel={(option) => areas.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={areasLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={areas.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Areas"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="driving_licence"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(drivingLicences.keys())}
              getOptionLabel={(option) => drivingLicences.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={drivingLicencesLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Driving Licence"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        {/* <Controller
          name="driving_licence"
          control={control}
          render={({ field }) => (
            <FormControl fullWidth variant="filled" margin="dense">
              <InputLabel id="driving_licence">Driving Licence</InputLabel>
              <Select
                labelId="Driving Licence"
                {...field}
                value={drivingLicencesLoading ? "" : field.value}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Array.from(drivingLicences, ([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        /> */}
        <Stack direction="row" sx={{ gap: 1 }}>
          <Controller
            name="min_salary"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Min Salary"
                variant="filled"
                margin="dense"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="max_salary"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Max Salary"
                variant="filled"
                margin="dense"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Controller
          name="max_notice_period"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(noticePeriods.keys())}
              getOptionLabel={(option) => noticePeriods.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={noticePeriodsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Maximum Notice Period"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(statuses.keys())}
              getOptionLabel={(option) => statuses.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={statusesLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Stack direction="row" justifyContent="space-between" spacing={0}>
          <Controller
            name="permanent_resident"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="primary"
                  />
                }
                label="Resident"
                labelPlacement="top"
              />
            )}
          />
          <Controller
            name="qualifies_for_bee"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="primary"
                  />
                }
                label="BEE"
                labelPlacement="top"
              />
            )}
          />
          <Controller
            name="valid_work_permit"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="primary"
                  />
                }
                label="Permit"
                labelPlacement="top"
              />
            )}
          />
        </Stack>
        <Box sx={{ mt: 1 }}>
          <Button
            type="submit"
            startIcon={<SearchIcon />}
            variant="contained"
            fullWidth
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
}
