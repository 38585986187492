import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { Control, Controller, useFormState } from "react-hook-form";
import useSectors from "../../data/Sectors";
import { IClient } from "../../data/Clients";

interface IClientDetailsFormProps {
  control: Control<IClient>;
  disabled?: boolean;
  clientLoading?: boolean;
}

export default function ClientDetailsForm({
  control,
  disabled,
  clientLoading,
}: IClientDetailsFormProps) {
  const { errors } = useFormState({
    control,
  });
  const [sectors, sectorsLoading] = useSectors();

  const loading = sectorsLoading || (clientLoading ?? false);

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="Client" />
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Box>
          {loading ? null : (
            <>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
              <Controller
                name="sectors"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(sectors.keys())}
                    getOptionLabel={(option) => sectors.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    loading={sectorsLoading}
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={sectors.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sectors"
                        variant="filled"
                        margin="dense"
                        error={!!errors.sectors}
                        helperText={(errors.sectors as any)?.message}
                      />
                    )}
                  />
                )}
              />
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}
