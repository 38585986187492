import axios from "axios";
import React from "react";
import * as yup from "yup";
import { INotes, noteSchema } from "./Notes";
import { contactSchema, IContact } from "./Contacts";

export interface IClient extends INotes {
  id?: number;
  name: string;
  logo?: string;
  folder?: string;
  sectors: number[];
  contacts: IContact[];
}

export interface IClientList {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: number;
    name: string;
    logo: string;
    folder: string;
    sectors: string[];
  }[];
}

export const clientSchema: yup.SchemaOf<IClient> = yup.object({
  id: yup.number().optional(),
  name: yup.string().required("Name is a required field."),
  logo: yup.string().optional(),
  folder: yup.string().optional(),
  sectors: yup.array().of(yup.number().integer().required()).min(1),
  notes: yup.array().of(noteSchema).optional(),
  contacts: yup.array().of(contactSchema).optional(),
});

export function useClients(query: string): [IClientList | null, boolean] {
  const [clients, setClients] = React.useState<IClientList | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<IClientList>(`/clients/?${query}`)
      .then((response) => {
        setClients(response.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [query]);

  return [clients, loading];
}

export function useClient(
  id: string
): [
    IClient | null,
    React.Dispatch<React.SetStateAction<IClient | null>>,
    boolean
  ] {
  const [client, setClient] = React.useState<IClient | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<IClient>(`/clients/${id}/`)
      .then((response) => {
        setClient(response.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);
  return [client, setClient, loading];
}

export function updateClient(id: number, details: IClient) {
  // const client = filterid(details);
  axios
    .patch(`/clients/${id}/`, details)
    .then((response) => console.log(response))
    .catch((error) => console.log(error));
}
