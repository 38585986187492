import axios from "axios";
import React from "react";

interface ITitle {
  id: number;
  name: string;
}

export default function useTitles(): [Map<number, string>, boolean] {
  const [titles, setTitles] = React.useState<Map<number, string>>(new Map());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<ITitle[]>("/titles/")
      .then((response) => {
        setTitles(new Map(response.data.map((x) => [x.id, x.name])));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return [titles, loading];
}
