export default function useNoticePeriods(): [Map<number, string>, boolean] {
  const noticePeriods = new Map()
  noticePeriods.set(0, "Immediately");
  noticePeriods.set(1, "One Week");
  noticePeriods.set(2, "Two Weeks");
  noticePeriods.set(4, "One Month");
  noticePeriods.set(8, "Two Months");
  noticePeriods.set(12, "Three Months");
  return [noticePeriods, false]
}
