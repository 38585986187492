import axios from "axios";
import React from "react";

interface INationality {
  id: number;
  country: string;
  continent: string;
}

export default function useNationalities(): [Map<number, string>, boolean] {
  const [nationalities, setNationalities] = React.useState<Map<number, string>>(
    new Map()
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<INationality[]>("/nationalities/")
      .then((response) => {
        setNationalities(new Map(response.data.map((x) => [x.id, x.country])));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return [nationalities, loading];
}
