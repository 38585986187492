import axios from "axios";
import React from "react";

interface IExperience {
  id: number;
  name: string;
}

export default function useExperience(): [Map<number, string>, boolean] {
  const [experience, setExperience] = React.useState<Map<number, string>>(
    new Map()
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<IExperience[]>("/experience/")
      .then((response) => {
        setExperience(new Map(response.data.map((x) => [x.id, x.name])));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return [experience, loading];
}
