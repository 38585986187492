import React from "react";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

interface INameAutocompleteProps {
  section: string;
  name: string;
  onChange: (...event: any) => void;
  value: string;
}

export default function NameAutocomplete({
  section,
  name,
  onChange,
  value,
}: INameAutocompleteProps) {
  const [search, setSearch] = React.useState<string>("");
  const [options, setOptions] = React.useState<string[]>([]);

  React.useEffect(() => {
    const source = axios.CancelToken.source();
    const timeoutHandler = setTimeout(() => {
      axios
        .get<string[]>(`/${section}/names/?search=${search}`, {
          cancelToken: source.token,
        })
        .then((response) => setOptions(response.data))
        .catch((error) => console.log(error));
    }, 300);

    return () => {
      clearTimeout(timeoutHandler);
      source.cancel();
    };
  }, [section, search]);

  return (
    <Autocomplete
      autoComplete
      options={options}
      freeSolo
      includeInputInList
      fullWidth
      value={value}
      onChange={(_event, x) => onChange(x ?? "")}
      onInputChange={(_event, search) => {
        onChange(search);
        setSearch(search);
      }}
      filterOptions={(x) => x}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Name"
          name={name}
          variant="filled"
          fullWidth
          margin="dense"
        />
      )}
    />
  );
}
