import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import useSectors from "../../data/Sectors";
import { Control, Controller } from "react-hook-form";
import { IClientFilter } from "./ClientListPage";
import NameAutocomplete from "../misc/NameAutocomplete";

interface ClientListFilterProps {
  control: Control<IClientFilter, object>;
}

export default function Filter({ control }: ClientListFilterProps) {
  const [sectors, sectorsLoading] = useSectors();

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: "grey.300" }}>
        <Typography variant="h6" component="div">
          Filters
        </Typography>
      </Toolbar>
      <Box sx={{ pl: 2, pr: 2 }}>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <NameAutocomplete
              section="clients"
              name={field.name}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        <Controller
          name="sectors"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(sectors.keys())}
              getOptionLabel={(option) => sectors.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={sectorsLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    variant="outlined"
                    size="small"
                    label={sectors.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sectors"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Box sx={{ mt: 1 }}>
          <Button
            type="submit"
            startIcon={<SearchIcon />}
            variant="contained"
            fullWidth
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
}
