import axios from "axios";
import React from "react";

interface IDrivingLicence {
  id: number;
  name: string;
}

export default function useDrivingLicences(): [Map<number, string>, boolean] {
  const [drivingLicences, setDrivingLicences] = React.useState<Map<number, string>>(new Map());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<IDrivingLicence[]>("/drivinglicencetypes/")
      .then((response) => {
        setDrivingLicences(new Map(response.data.map((x) => [x.id, x.name])));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return [drivingLicences, loading];
}
