import Button from "@mui/material/Button";
import React from "react";

export interface ITabButtonProps {
  onClick: any;
  children?: React.ReactNode;
}

export default function TabButton({ onClick, children }: ITabButtonProps) {
  return (
    <Button sx={{ marginLeft: "auto" }} onClick={onClick}>
      {children}
    </Button>
  );
}
