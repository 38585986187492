import React from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Delete from "@mui/icons-material/Delete";
import {
  Control,
  Controller,
  useFieldArray,
  UseFormRegister,
  useFormState,
} from "react-hook-form";
import { IClient } from "../../data/Clients";
import TabButton from "../misc/TabButton";

interface IClientContactForm {
  control: Control<IClient>;
  register: UseFormRegister<IClient>;
  disabled?: boolean;
  clientLoading?: boolean;
}

export default function ClientContactForm({
  control,
  register,
  disabled,
  clientLoading,
}: IClientContactForm) {
  const { errors } = useFormState({
    control,
  });
  const { fields, prepend, remove } = useFieldArray({
    control,
    name: "contacts",
    keyName: "key",
  });

  const addContact = () => {
    prepend({ name: "", email: "", position: "", number: "" });
  };

  const loading = clientLoading ?? false;

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="Contacts" />
        <TabButton onClick={addContact}>Add</TabButton>
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Box>
          {fields.map((contact, index) => (
            <Box
              key={contact.key}
              sx={{ borderBottom: 1, borderColor: "grey.300", pb: 1 }}
            >
              {contact.id != null ? (
                <input
                  type="hidden"
                  {...register(`contacts.${index}.id` as const)}
                  defaultValue={contact.id}
                />
              ) : null}
              <Controller
                name={`contacts.${index}.name` as const}
                control={control}
                defaultValue={contact.name}
                render={({ field }) => (
                  <TextField
                    label="Name"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors?.contacts?.[index]?.name}
                    helperText={errors?.contacts?.[index]?.name?.message}
                  />
                )}
              />
              <Controller
                name={`contacts.${index}.position` as const}
                control={control}
                defaultValue={contact.position}
                render={({ field }) => (
                  <TextField
                    label="Position"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors?.contacts?.[index]?.position}
                    helperText={errors?.contacts?.[index]?.position?.message}
                  />
                )}
              />
              <Controller
                name={`contacts.${index}.email` as const}
                control={control}
                defaultValue={contact.email}
                render={({ field }) => (
                  <TextField
                    label="Email"
                    variant="filled"
                    margin="dense"
                    disabled={disabled}
                    fullWidth
                    {...field}
                    error={!!errors?.contacts?.[index]?.email}
                    helperText={errors?.contacts?.[index]?.email?.message}
                  />
                )}
              />
              <Box sx={{ display: "flex" }}>
                <Controller
                  name={`contacts.${index}.number` as const}
                  control={control}
                  defaultValue={contact.number}
                  render={({ field }) => (
                    <TextField
                      label="Number"
                      variant="filled"
                      margin="dense"
                      disabled={disabled}
                      {...field}
                      error={!!errors?.contacts?.[index]?.number}
                      helperText={errors?.contacts?.[index]?.number?.message}
                    />
                  )}
                />
                <IconButton
                  sx={{ mt: 1, alignSelf: "center", ml: "auto" }}
                  onClick={() => remove(index)}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Fade>
    </>
  );
}
